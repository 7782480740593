import { Link } from 'gatsby';
import React, { useState } from 'react';
import '../../styles/project-main/project-box.scss';
import '../../styles/project-main/project-box-mobile.scss';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

// Animations
const childVariants = {
    hidden: {
        x: '-30px',
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1
    }
};

const ListProjects = ({ link, listKey, artist, title, arrow, img, index }) => {
    const [count, setCount] = useState('closed');

    const [id, setId] = useState('projectOpen');

    const handleClick = (index, e) => {
        if (window.innerWidth < 850) {
            e.preventDefault();
            setCount(index);
            setId('projectOpen');
        }
    };

    const handleCloseClick = () => {
        setId('closed');
    };

    return (
        <motion.div
            className="projects__link"
            variants={childVariants}
            id={count === index ? id : 'project-list-item'}
            key={listKey}>
            <div className="projectLine"></div>
            <div
                className="projectBox__close"
                onClick={() => handleCloseClick()}
                onKeyDown={() => handleCloseClick()}
                aria-hidden="true"></div>
            <div className="projectBox">
                <Link
                    to={'/projects/' + link}
                    key={listKey}
                    className="projectBox__title"
                    onClick={(e) => handleClick(index, e)}
                    onKeyDown={(e) => handleClick(index, e)}>
                    <div className="projectBox__text">
                        <h2 className="font__large">{artist}</h2>
                        <p className="font__mid sub-heading">{title}</p>
                    </div>
                </Link>
                <Link to={'/projects/' + link} key={'second-link' + listKey}>
                    <div className="projectBox__image">
                        <div className="projectBox__more">
                            <p className="font__small--more font__sans">Find out more</p>
                            <img src={arrow} alt="arrow" />
                        </div>
                        <figure>
                            {img && (
                                <GatsbyImage image={img.node.localFile.childImageSharp.gatsbyImageData} alt="Artwork" />
                            )}
                        </figure>
                    </div>
                </Link>
            </div>
        </motion.div>
    );
};

export default ListProjects;
