import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import '../../styles/project-main/project-box.scss';
import '../../styles/project-main/project-box-mobile.scss';
import arrow from './../../images/blackArrow.png';
import { motion } from 'framer-motion';
import ListProjects from './list-projects';

// Animations
const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            type: 'tween',
            when: 'beforeChildren',
            staggerChildren: 0.4
        }
    }
};

const childVariants = {
    hidden: {
        x: '-30px',
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1
    }
};

const IndexPage = ({ data }) => {
    const projects = data.allWpPost.edges;
    const updatedProjects = projects.filter((project) => project.node.project.specialProject !== true);
    const specialProjects = projects.filter((project) => project.node.project.specialProject === true);

    return (
        <Layout>
            <motion.main className="site-wrap" variants={containerVariants} initial="hidden" animate="visible">
                <div className="projects__outerContainer">
                    {updatedProjects.map((project, index) => {
                        return (
                            <ListProjects
                                link={project.node.slug}
                                listKey={project.node.id}
                                artist={project.node.project.artistName[0].name}
                                title={project.node.project.workTitle}
                                arrow={arrow}
                                img={project.node.featuredImage}
                                index={index}
                                newClass={'projects__link'}
                                key={project.node.project.workTitle}
                            />
                        );
                    })}

                    {specialProjects[0].node.title && (
                        <motion.div variants={childVariants} className="projects__special">
                            <div className="projects__specialLine" key="special"></div>
                            <p className="font__mid font__italic">Special projects</p>
                        </motion.div>
                    )}

                    {specialProjects.map((project, index) => {
                        return (
                            <ListProjects
                                link={project.node.slug}
                                listKey={project.node.id}
                                artist={project.node.project.artistName[0].name}
                                title={project.node.project.workTitle}
                                arrow={arrow}
                                img={project.node.featuredImage}
                                index={index}
                                newClass={'projects__link--special'}
                                key={project.node.project.workTitle}
                            />
                        );
                    })}
                </div>
            </motion.main>
        </Layout>
    );
};

export const query = graphql`
    query ProjectsPage {
        allWpPost(sort: { order: DESC, fields: [date] }) {
            edges {
                node {
                    id
                    title
                    slug
                    content
                    project {
                        workTitle
                        specialProject
                        artistName {
                            name
                        }
                    }
                    categories {
                        nodes {
                            name
                        }
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 82, formats: [WEBP])
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
